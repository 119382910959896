<template>
  <div class="row offers mt-1">
    <b-col lg="12">
      <div class="card">
        <div class="card-body">
          <h3 class="h3 text-center pt-1">Premium Offers</h3>
          <div class="row justify-content-end mb-1">
            <span class="pr-2">
              <router-link
                class="btn btn-sm btn-dark"
                :to="{ name: PremiumCreatePath.name }"
              >
                <i class="fa fa-plus"></i>
              </router-link>
            </span>
            <span>
              <button class="btn btn-sm btn-dark" @click="actionGetOffers">
                <i class="fa fa-refresh"></i>
              </button>
            </span>
            <div class="col-sm-auto">
              <label for="">
                Count :
                <strong class="pl-2">
                  {{ totalRows }}
                </strong>
              </label>
            </div>
          </div>
          <b-table
            sticky-header="500px"
            responsive="xl"
            :items="items"
            :fields="fields"
            :busy="isLoading"
            :isLoading="isLoading"
            :tbody-tr-class="rowClass"
            show-empty
            hover
            striped
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
          >
            <template v-slot:table-busy>
              <div class="text-center text-dark my-2">
                <b-spinner
                  class="align-middle"
                  variant="dark"
                  type="grow"
                  label="Loading .."
                >
                </b-spinner>
                Loading ..
              </div>
            </template>
            <template #head()="row">
              <div class="text-nowrap">
                {{ row.label }}
              </div>
            </template>
            <template #cell(No)="row">
              {{ (currentPage - 1) * perPage + (row.index + 1) }}
            </template>
            <template #cell(offers)="row">
              {{ OfferType(row.item.offer_type_id) }}
            </template>
            <template #cell(name)="row">
              <router-link
                :to="{
                  name: PremiumItemPath.name,
                  params: { id: row.item.id },
                }"
                class="btn btn-sm btn-success"
              >
                {{ row.item.name }}
              </router-link>
              <strong> #{{ row.item.id }}</strong>
            </template>
            <template #cell(discount)="row">
              {{ row.item.discount_id }}
            </template>
            <template #cell(priceidr)="row">
              {{ formatPrice(row.item.price_idr, 'IDR') }}
            </template>
            <template #cell(priceusd)="row">
              {{ formatPrice(row.item.price_usd, 'USD') }}
            </template>
            <template #cell(offer_status)="row">
              <div style="min-width: 100px;">
                {{ OfferStatus(row.item.offer_status) }}
              </div>
            </template>
            <template #cell(is_free)="row">
              <div>
                <span v-if="row.item.is_free" class="badge badge-success">
                  Y
                </span>
                <span v-else class="badge badge-danger"> N </span>
              </div>
            </template>
            <template #cell(action_edit)="row">
              <router-link
                class="btn btn-default"
                :to="{
                  name: PremiumEditPath.name,
                  params: { id: row.item.id },
                }"
              >
                <i class="fa fa-edit"> </i>
              </router-link>
            </template>
            <template #cell(action_detail)="row">
              <router-link
                target="_blank"
                class="btn btn-default"
                :to="{ name: PremiumDetail.name, params: { id: row.item.id } }"
              >
                <i class="fa fa-eye"></i>
              </router-link>
            </template>
          </b-table>
        </div>
        <b-row class="justify-content-start ml-1">
          <b-col lg="3">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  PremiumCreatePath,
  PremiumEditPath,
  PremiumItemPath,
  PremiumDetail,
} from '../../router/marketing';
import constant from '../../store/constant';
export default {
  name: 'PremiumOffers',
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      fields: [
        { key: 'No', label: 'No' },
        // { key:'offers', label: 'Offers'},
        { key: 'name', label: 'Offers Name' },
        { key: 'discount', label: 'discount' },
        {
          key: 'priceidr',
          label: 'Web Price IDR',
          thClass: 'text-right',
          tdClass: 'text-right',
          sortable: true,
        },
        {
          key: 'priceusd',
          label: 'Web Price usd',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'is_free',
          label: 'Is Free',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        { key: 'offer_status', label: 'Offer Status' },
        {
          key: 'created',
          label: 'Created',
          formatter: function(value) {
            return moment(value).format('DD-MM-YYYY');
          },
          class: 'text-nowrap',
        },
        { key: 'action_edit', label: 'Edit' },
        { key: 'action_detail', label: 'Detail' },
      ],
      sortBy: 'created',
      sortDesc: false,
      PremiumCreatePath,
      PremiumEditPath,
      PremiumItemPath,
      PremiumDetail,
    };
  },
  computed: {
    ...mapGetters('offerspremium', { items: 'premium' }),
    ...mapState({
      isLoading: (state) => state.offerspremium.isLoading,
      isError: (state) => state.offerspremium.isError,
      totalRows: (state) => state.offerspremium.totalRows,
    }),
  },
  mounted() {
    this.actionGetOffers();
  },
  watch: {
    currentPage: function() {
      this.actionGetOffers();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Premium Offers | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  methods: {
    ...mapActions('offerspremium', ['fetchOffers']),
    actionGetOffers() {
      let payload = {
        page: this.currentPage,
        limit: this.perPage,
      };

      this.fetchOffers(payload);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatDate(tgl) {
      return moment(tgl).format('DD MMM YY hh:mm');
    },
    formatDateValid(from, to) {
      return (
        moment(from).format('DD MMM YY') +
        ' - ' +
        moment(to).format('DD MMM YY')
      );
    },
    OfferType(key) {
      if (key == 1) {
        return 'Single';
      } else if (key == 2) {
        return 'Subscriptions';
      } else if (key == 3) {
        return 'Bundle';
      } else if (key == 4) {
        return 'Buffet';
      }
    },
    OfferStatus(key) {
      if (key == 1) {
        return 'New';
      } else if (key == 2) {
        return 'Waitting For Review';
      } else if (key == 3) {
        return 'In Review';
      } else if (key == 4) {
        return 'Rejected';
      } else if (key == 5) {
        return 'Approved';
      } else if (key == 6) {
        return 'Prepare for sale';
      } else if (key == 7) {
        return 'Ready For Sale';
      } else if (key == 8) {
        return 'Not For Sale';
      }
    },
    formatPrice(value, currency) {
      if (value) {
        var formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },
    rowClass(item) {
      if (!item || !item?.offer_status) return;
      if (item.offer_status === constant.OFFER_STATUS.NOT_FOR_SALE)
        return 'table-danger';
    },
  },
};
</script>

<style scoped>
@import '../../assets/css/table.css';
</style>
